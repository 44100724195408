:root {
  font-size: 62.5%;

  background-color: #e0e0e0;
}

.form {
  max-width: 374px;
  margin: 0 auto;
  padding-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}

.result {
  margin-right: 2.4rem;

  font-size: 1.8rem;
  text-align: right;
}

.input {
  margin: 0 auto;
  padding: 0 2.4rem;
  width: 100%;
  max-width: calc(374px - 4.8rem);
  height: 8rem;
  border-radius: 2.6rem;
  border: none;

  background: #e0e0e0;
  color: #494949;

  font-size: 2.4rem;
  text-align: right;
  font-weight: bold;

  box-shadow: 8px 8px 16px #bebebe, -8px -8px 16px #ffffff;
}

.input:active,
.input:focus {
  outline: none;
}

.calc {
  margin: 0 auto;
  width: 374px;
  height: 490px;
  display: grid;
  gap: 1.8rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
}
